@import '../../scss/theme-bootstrap';
@import '../components/_mpp_filters';

.sd-mount-skeleton {
  .sd-grid-skeleton {
    padding: 0;
    .sd-grid-skeleton-filters {
      padding: 0;
      @media #{$medium-up} {
        padding: 40px 12.5px 10px;
      }
      @media #{$large-up} {
        padding: 10px 37.5px;
      }
      .skeleton-bar {
        height: 40px;
        @media #{$medium-up} {
          height: 27px;
        }
      }
    }
    .sd-grid-skeleton-items {
      .skeleton-item {
        padding: 0 0 30px;
        @media #{$medium-up} {
          padding: 17.5px 12.5px 34px;
        }
        @media #{$large-up} {
          padding: 17.5px 37.5px 34px;
        }
        .skeleton {
          &-box {
            @media #{$medium-up} {
              padding-top: 133%;
            }
          }
          &-details {
            padding-top: 20px;
            align-items: flex-start;
            @media #{$medium-up} {
              padding-top: 0;
            }
          }
        }
        a.elc-clickable-wrapper {
          margin: 0;
        }
      }
      &.is-slider {
        .skeleton-item {
          @media #{$large-up} {
            padding: 17.5px 17.5px 34px;
          }
        }
      }
    }
  }
}

.elc-carousel-section-wrapper {
  position: relative;
  margin: 0px auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.elc-search-results-wrapper,
.elc-search-enhanced-grid-wrapper,
.sd-product-grid {
  position: relative;
  max-width: $container-max-width;
  margin: 0 auto;
  min-height: 700px;
  color: $color-navy;
  .elc-product-grid-header,
  &__title {
    font-size: 40px;
    font-family: $optimalight;
    letter-spacing: -0.02em;
    text-align: center;
    line-height: 45px;
    z-index: 1;
    @media #{$medium-up} {
      padding: 0;
      position: relative;
      top: 14px;
      margin-bottom: 30px;
    }
  }
  .elc-product-grid-header {
    -webkit-font-smoothing: inherit;
  }
  .elc-product-grid-header {
    &.brand-style-renutriv {
      @include renutriv-header;
      line-height: get-line-height(32px, 36px);
      color: $color-black;
      font-size: 36px;
    }
  }
  .elc-grid-item-tout {
    margin: 0;
    padding: 13px;
    position: relative;
    @media #{$medium-up} {
      padding: 17.5px 37.5px 24px;
    }
  }
  .elc-product-carousel-container {
    .elc-product-full-cta-wrapper {
      position: inherit;
      padding-top: 38px;
      .elc-add-to-bag-button {
        position: absolute;
        bottom: 0;
      }
    }
  }
  .elc-product-quick-view-button {
    @include button--secondary;
    position: absolute;
    bottom: 0;
    letter-spacing: 1.25px;
    @media #{$medium-up} {
      @include ternary($text-direction == rtl, transform, translate(50%, 0), translate(-50%, 0));
      top: -250px;
      #{$ldirection}: 50%;
      [dir='rtl'] & {
        margin: 0;
      }
    }
    background-color: $color-white;
    color: $color-navy;
    border-color: $color-navy;
    font-weight: bold;
    width: 135px;
    font-size: 12px;
    font-family: inherit;
    &:hover {
      background-color: $color-white;
    }
    &:focus {
      outline: 1px dotted $color-black;
    }
  }
  .elc-product-brief-view {
    @media #{$large-only} {
      max-width: 776px;
      margin-#{$rdirection}: auto;
      margin-#{$ldirection}: auto;
    }
    .elc-grid-item-product {
      .elc-product-brief {
        display: initial;
      }
    }
    &.is-slider {
      .elc-product-images-wrapper {
        @media #{$medium-up} {
          height: 224px;
        }
      }
      .elc-product-display-name-wrapper,
      .elc-rating-component-container,
      .elc-short-description-container {
        @media #{$medium-up} {
          padding: 0;
        }
      }
    }
  }
  .elc-grid-item-product {
    margin: 0;
    padding: 0;
    position: relative;
    text-align: $ldirection;
    @media #{$medium-up} {
      padding: 17.5px 12.5px 24px;
    }
    @media #{$large-up} {
      padding: 17.5px 37.5px 24px;
    }
    .elc-product-display-name,
    .elc-product-display-name-link {
      @include headline--quinary;
      font-family: $optimaregular, $optimalight;
      color: $color-navy;
      line-height: 24px;
      letter-spacing: 0.02em;
      position: relative;
      z-index: 1;
      margin: 0;
      &:hover {
        text-decoration: none;
      }
    }
    .elc-product-subheader,
    .elc-product-sub-display-name {
      @include headline--senary;
      font-family: $optimaregular, $optimalight;
      color: $color-navy;
      margin: 0;
      line-height: 25px;
      overflow: hidden;
      font-size: 21px;
      @media #{$medium-up} {
        font-size: 15px;
        line-height: 20px;
      }
    }
    .elc-product-description-wrapper,
    .elc-product-short-description-wrapper {
      line-height: 18px;
      color: $color-navy;
      margin-bottom: 9px;
      font-family: $akzidenz;
      font-size: 15px;
    }
    .elc-product-name-wrapper,
    .elc-product-display-name-wrapper {
      display: block;
      margin: 0 0 5px;
    }
    .elc-product-display-name-link-subheader {
      display: inline-block;
      width: 100%;
      margin-top: 5px;
    }
    .elc-product-images-wrapper {
      height: 0;
      padding-bottom: 100%;
      position: relative;
      width: 100%;
      @media #{$medium-up} {
        height: 285px;
      }
      @media #{$large-up} {
        height: 424px;
      }
      .elc-img {
        height: 100%;
        position: absolute;
        width: 100%;
        @media #{$large-up} {
          max-width: 308px;
        }
      }
    }
    .elc-product-shades-number-wrapper {
      text-transform: lowercase;
      line-height: 16px;
    }
    .elc-product-rating-wrapper {
      order: 2;
      display: inline;
      .elc-rating {
        margin: 4px 0 12px;
        .elc-average-rating-text {
          display: none;
        }
        .elc-reviews-number {
          font-size: 15px;
          font-family: $optimaregular;
          color: $color-navy;
        }
        .elc-read-reviews {
          display: none;
        }
      }
    }
    .elc-product-cta-wrapper,
    .elc-product-full-cta-wrapper {
      height: 0;
      display: initial;
    }
    .elc-product-shade-picker-wrapper,
    .elc-shade-swatches-wrapper {
      position: relative;
      margin-#{$ldirection}: 0;
      margin-top: 5px;
      margin-bottom: 10px;
      width: 100%;
      height: 30px;
      top: 0;
      .elc-shade-carousel {
        height: 100%;
        overflow: hidden;
        .elc-grid-column {
          display: none;
        }
        > div {
          padding: 0;
          height: 100%;
          width: 108%;
          justify-content: $ldirection;
          &.elc-product-shade-picker-slider {
            padding: 0;
            @media #{$medium-up} {
              @include swap_direction(padding, 2px 14px 0 12px);
            }
          }
          @media #{$medium-up} {
            width: 100%;
          }
          .elc-single-shade-wrapper .elc-shade-icon {
            height: 16px;
            width: 16px;
            @media #{$medium-up} {
              height: 23px;
              width: 23px;
            }
          }
        }
      }
      .elc-product-shades-number {
        text-transform: lowercase;
      }
      .elc-remove-icon {
        display: none;
      }
      .elc-shade-image {
        border-width: 1px;
        cursor: pointer;
        display: inline-block;
        height: 25px;
        margin: 1px;
        width: 25px;
        border: none;
        background-color: transparent;
        @media #{$medium-up} {
          border-width: 2px;
          height: 100%;
          max-height: 23px;
          max-width: 23px;
          min-height: 18px;
          min-width: 18px;
          width: 100%;
        }
        > div:first-child {
          position: relative;
        }
        &:hover,
        &-selected {
          box-shadow: 0 0 0 1px $color-navy;
          border-radius: 50%;
        }
      }
      .elc-single-shade-wrapper {
        margin-#{$ldirection}: 10px;
        justify-content: normal;
        @media #{$medium-up} {
          margin-#{$ldirection}: 0;
        }
      }
      .elc-absolute-shade-wrapper {
        &:hover {
          box-shadow: 0 0 0 1px $color-navy;
          border-radius: 50%;
        }
      }
      .elc-shade-image-selected {
        .elc-absolute-shade-wrapper {
          box-shadow: 0 0 0 1px $color-navy;
          border-radius: 50%;
        }
      }
      .slick-slide {
        text-align: center;
      }
      .elc-slider-arrow-wrapper {
        display: none;
        @media #{$medium-up} {
          top: 6px;
          display: block;
        }
        &.slick-disabled .elc-directional-icon {
          background-color: $color-darker-gray;
        }
      }
      p {
        font-family: inherit;
        font-size: 15px;
      }
      .slick-prev,
      .slick-next {
        background: transparent;
        &:before {
          display: none;
        }
      }
      .slick-next {
        #{$rdirection}: -16px;
      }
      .slick-track {
        margin-#{$ldirection}: -1%;
        @media #{$medium-up} {
          margin: 0;
        }
      }
    }
    .elc-product-price-range,
    .elc-price-formatted {
      color: $color-navy;
      font-family: $akzidenz;
      font-size: 15px;
      font-weight: normal;
      line-height: 18px;
    }
    .elc-product-price-range,
    .elc-product-original-price {
      font-weight: bold;
      padding-#{$rdirection}: 10px;
    }
    .elc-price-formatted.elc-product-price {
      color: $color-red;
      font-weight: bold;
      &:last-child {
        @include swap_direction(margin, 0 0 0 15px);
      }
    }
    .elc-product-price-row-wrapper {
      display: flex;
      height: 20px;
    }
    .elc-price-formatted-wrapper {
      align-self: center;
      font-weight: bold;
    }
    .elc-product-quick-view-container-wrapper {
      margin: 0;
    }
    .elc-product-quick-view-button-wrapper {
      position: relative;
      height: 0;
      .elc-product-quick-view-button {
        display: block;
        width: auto;
        letter-spacing: 1.25px;
        @media #{$medium-up} {
          display: none;
        }
        &:hover {
          display: block;
        }
      }
    }
    &:hover,
    &:focus-within {
      .elc-product-quick-view-button {
        display: block;
      }
    }
    .elc-product-price-wrapper,
    .elc-product-prices-wrapper,
    .elc-product-prices-clickable-wrapper {
      margin-bottom: 8px;
      @media #{$medium-up} {
        @include swap_direction(margin, 0 15px 8px 0);
      }
      order: 5;
      .elc-product-tax-included-label-wrapper {
        float: #{$ldirection};
        vertical-align: middle;
        color: $color-navy;
        font-family: $akzidenz;
        font-size: 15px;
        line-height: 18px;
      }
      .elc-product-price-range-row-wrapper {
        margin: 0;
      }
      .elc-product-price-per-unit-row-wrapper {
        margin: 0;
        .elc-product-price-per-unit {
          margin: 5px 0;
        }
      }
    }

    .elc-product-brief-detail-wrapper,
    .elc-product-brief {
      position: relative;
      text-align: $ldirection;
      margin: 0 0 20px;
      padding: 0;
      height: auto;
      width: 100%;
      @media #{$medium-up} {
        padding: 40px 0 0;
        margin: 5px 0 0;
      }
      .elc-product-item-wrapper,
      .elc-clickable-wrapper {
        display: block;
        margin: 10px;
        @media #{$medium-up} {
          margin: 10px 0;
          position: relative;
        }
        &.elc-image-slider-images-wrapper {
          margin: 0;
        }
        &.elc-product-shade-picker-wrapper,
        & + .elc-shade-swatches-wrapper {
          margin-#{$ldirection}: 0;
          .elc-single-shade-wrapper {
            display: block;
            margin-#{$ldirection}: 2.5%;
            @media #{$medium-up} {
              margin-#{$ldirection}: 0;
            }
            div {
              height: 25px;
              width: 25px;
              @media #{$large-up} {
                height: 23px;
                width: 23px;
              }
            }
            p {
              margin-#{$ldirection}: 25px;
              margin-top: -17px;
            }
          }
        }
      }
      .elc-rating-component,
      .elc-rating {
        margin: 0;
      }
    }
    .elc-carousel-section-wrapper,
    .elc-styled-product-image-container,
    .elc-product-images-wrapper {
      .elc-slider-arrow-wrapper {
        width: 22px;
        height: 41px;
        z-index: 1;
        &.slick-prev {
          #{$ldirection}: 0;
        }
        &.slick-next {
          #{$rdirection}: 0;
          &:before {
            #{$rdirection}: 0;
          }
        }
        &:before {
          content: unset;
        }
        &:before {
          content: '';
          display: inline-block;
          background: $color-medium-gray;
          height: 96px;
          position: absolute;
          width: 40px;
          top: -26px;
          opacity: 0.25;
        }
        .elc-icon {
          position: absolute;
          transform: unset;
          width: 34px;
          height: 34px;
          display: block;
          top: 5px;
        }
        .elc-slider-arrow-left {
          @include svg-icon-inline-mask('arrow-left.svg');
        }
        .elc-slider-arrow-right {
          @include svg-icon-inline-mask('arrow-right.svg');
          #{$rdirection}: 0;
        }
      }
      .slick-slider {
        margin-bottom: 10px;
      }
    }
    .elc-slider-view-wrapper {
      padding: 0;
      @media #{$medium-up} {
        padding: 0px 10px;
      }
    }
    .elc-product-brief-detail-wrapper {
      padding-top: 20px;
      .elc-product-shade-picker-wrapper {
        order: 1;
      }
      .elc-product-product-name-wrapper {
        order: 2;
      }
      .elc-product-rating-wrapper {
        order: 3;
      }
      .elc-product-description-wrapper {
        order: 4;
      }
      .elc-product-price-wrapper {
        order: 5;
      }
      .elc-product-shades-number-wrapper {
        order: 6;
      }
    }

    // Renutriv Dynamic Skeleton
    .elc-product-display-name-link,
    .elc-product-display-name,
    .elc-product-subheader {
      &.brand-style-renutriv {
        @include renutriv-header;
        font-size: 32px;
        line-height: get-line-height(32px, 36px);
        color: $color-black;
        text-transform: uppercase;
        @media (min-width: #{$tablet-width-min}) {
          font-size: 22px;
          line-height: get-line-height(22px, 24px);
        }
        @media (min-width: #{$max-width}) {
          font-size: 24px;
          line-height: get-line-height(24px, 28px);
        }
      }
    }
    .elc-product-subheader {
      &.brand-style-renutriv {
        @include renutriv-header;
        font-size: 32px;
        color: $color-black;
        text-transform: capitalize;
        letter-spacing: 0;
        line-height: 1.25;
        margin-bottom: 7px;
        @media #{$medium-up} {
          font-size: 17px;
          line-height: 1.2;
        }
      }
    }
    .elc-product-short-description-wrapper {
      &.brand-style-renutriv {
        font-family: $renutriv-roboto;
        font-size: 16px;
        color: $color-black;
        margin-bottom: 10px;
        line-height: 1.34;
      }
    }
  }
  .elc-product-sorting-dropdown {
    font-family: $akzidenz;
    text-transform: uppercase;
    color: $color-navy;
    border: none;
    font-size: 12px;
    position: relative;
    .elc-dropdown-wrapper,
    .elc-dropdown-input-wrapper {
      border: none;
      height: 27px;
      letter-spacing: 2px;
    }
    .elc-dropdown-readonly-input {
      display: inline-block;
      float: none;
      text-transform: uppercase;
      text-align: #{$rdirection};
      font-size: 12px;
    }
    .elc-dropdown-arrow {
      border: none;
      padding: 0;
      .elc-dropdown-arrow-icon {
        width: 8px;
        height: 11px;
      }
    }
    .elc-dropdown-options {
      width: 175px;
      position: absolute;
      #{$rdirection}: 0;
      text-align: $ldirection;
      .elc-dropdown-option {
        font-size: 12px;
        font-weight: normal;
        &:hover {
          background: $color-light-blue;
        }
        &:first-child {
          display: none;
        }
      }
    }
  }
  .elc-product-brief-wrapper {
    margin: 0;
    padding: 0;
    box-shadow: none;
    img.elc-img:nth-child(n + 2) {
      display: none;
    }
    &:hover {
      .has-alt-image {
        img.elc-img {
          display: none;
          &:nth-child(n + 2) {
            display: block;
          }
        }
      }
      box-shadow: none;
    }
    .elc-product {
      &-rating-wrapper {
        .elc-stars-overlay span {
          margin-#{$rdirection}: 4px;
        }
        .elc-star-icon {
          width: 14px;
          height: 14px;
          @media #{$medium-up} {
            width: 11px;
            height: 11px;
          }
        }
        .elc-reviews-number {
          color: $color-navy;
          font-family: $optimaregular;
          font-size: 17px;
          line-height: 1;
          @media #{$medium-up} {
            font-size: 15px;
          }
        }
        .elc-average-rating-text {
          & + .elc-rating-reviews {
            margin: 0 4px;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
  .elc-product-brief-image-slider-images-wrapper {
    .elc-play-icon-wrapper {
      background-color: transparent;
      border: 3px solid $color-white;
      opacity: 0.8;
      transition: opacity 300ms;
      width: 90px;
      height: 90px;
      .elc-play-icon {
        transform: scale(2.8);
        position: relative;
        #{$ldirection}: 4px;
      }
    }
    .elc-video-wrapper {
      padding-top: 100%;
      background-color: $color-black;
    }
    .elc-play-icon {
      background-color: $color-white;
    }
    &:hover {
      .elc-play-icon-wrapper {
        opacity: 1;
      }
    }
  }
  .elc-product-grid-with-sort-only {
    max-width: 1074px;
    margin: 0 13px;
    box-shadow: none;
    border-bottom: 1px solid $color-light-gray;
    background: transparent;
    text-align: $rdirection;
    select {
      @include swap_direction(margin, 10px 12px 5px 0);
      @include swap_direction(padding, 0 10px 0 23px);
      font-size: 10px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      color: $color-navy;
      text-transform: uppercase;
      border: none;
      width: 120px;
      float: $rdirection;
      @media #{$medium-up} {
        @include swap_direction(padding, 0 25px 0 10px);
        width: 93%;
        margin: 10px 4% 0;
        border: inherit;
        font-size: 12px;
      }
    }
    @media #{$medium-up} {
      margin: 0 auto;
      text-align: $ldirection;
      display: inline-block;
      width: 100%;
    }
  }
  .elc-sort-wrapper {
    padding: 0;
    width: auto;
    display: inline-block;
    float: $rdirection;
    select {
      font-size: 10px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      border: none;
      color: $color-navy;
      padding-#{$rdirection}: 35px;
      background-position: 100%;
      text-align: $rdirection;
      text-align-last: $rdirection;
      line-height: 1;
      word-break: break-all;
      max-width: 310px;
      white-space: normal;
      @media #{$medium-up} {
        width: 183px;
        padding-#{$rdirection}: 12px;
        background-position: 175px;
        font-size: 12px;
        max-width: none;
      }
    }
  }
  // To solve sort By alignment
  .elc-styled-filters,
  .elc-product-grid--header {
    @media #{$medium-up} {
      width: 100%;
      margin: 0;
    }
  }
  .elc-product-grid-wrapper {
    text-align: center;
    margin: 0;
  }
  .elc-product-quick-view-button-wrapper {
    min-height: 0;
    .elc-product-quick-view-button {
      display: block;
      width: auto;
      letter-spacing: 1.25px;
      @media #{$medium-up} {
        display: none;
      }
      &:hover {
        display: block;
      }
    }
  }
  .elc-product-full-cta-wrapper {
    box-shadow: none;
    bottom: 0;
    .elc-add-to-bag-button {
      border-radius: 0;
      white-space: nowrap;
      min-height: 40px;
      width: 135px;
      padding: 3px;
      font-size: 13px;
      font-family: $akzidenz;
      font-weight: 700;
      background-color: $color-navy;
    }
  }
  .elc-size-picker-container {
    width: 100%;
    .elc-size-picker-label {
      justify-content: $ldirection;
    }
  }
}

// Legacy Style Overrides
.sd-grid-formatter {
  overflow-anchor: auto;
  .elc-sd-content-anchor {
    top: -200px;
    position: relative;
  }
  .mpp_module_wrapper {
    margin-top: 0;
  }
  &--custom-bg {
    nav.breadcrumbs {
      background-color: transparent;
    }
    .sd-product-grid {
      .elc-product-sorting-dropdown {
        background-color: transparent;
      }
      .elc-product-brief-wrapper {
        background-color: transparent;
      }
      .elc-styled-filters,
      .elc-product-grid--header {
        background-color: transparent;
      }
      .elc-sort-wrapper {
        select {
          background-color: transparent;
        }
      }
    }
    .olapic-wall.grid {
      li.item {
        .olapic-item-info {
          border-color: transparent;
        }
        .olapic-item-media {
          border-color: transparent;
        }
      }
    }
  }
  #mpp_beauty {
    text-decoration: none;
    font-size: 14px;
    height: auto;
    @media #{$medium-up} {
      height: 40px;
    }
    a {
      color: inherit;
      outline: none;
    }
  }
}

// Loyalty Program specific styles
@if $loyalty_program == true {
  .elc-product-loyalty-message {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    max-width: 400px;
    width: 100%;
    @media #{$medium-up} {
      margin-bottom: 20px;
      margin-top: -15px;
    }
    + .elc-product-prices-wrapper,
    + .elc-product-prices-clickable-wrapper {
      margin: 0 0 20px;
      max-width: 400px;
      .elc-product-prices-wrapper,
      .elc-product-prices-clickable-wrapper {
        display: flex;
        .elc-product-original-price {
          font-size: 18px;
        }
        .elc-product-price-row-wrapper {
          margin: 0;
          width: 50%;
        }
      }
      ~ .elc-product-full-cta-wrapper {
        max-width: 400px;
        width: 100%;
        .elc-add-to-bag-button {
          width: 100%;
        }
      }
    }
    .elc-product-loyalty-message-price,
    .elc-product-loyalty-message-range {
      display: flex;
      font-size: 18px;
      .loyalty-label-short {
        display: none;
      }
      .loyalty-label-full {
        text-align: $ldirection;
      }
    }
    .elc-button {
      &.elc-product-loyalty-message-modal {
        &-open:hover,
        &-open {
          width: auto;
          height: 0;
          background: transparent;
          min-width: auto;
          line-height: 1;
          margin: -10px;
        }
      }
    }
    .elc-product-loyalty-message-link {
      font-family: $akzidenz;
      font-size: 17px;
      margin-bottom: 25px;
    }
  }

  // Loyalty Modal Styles
  .elc-button {
    &.elc-product-loyalty-message-modal {
      &-close:hover,
      &-close {
        width: 10px;
        height: 10px;
        background: transparent;
        min-width: auto;
        #{$rdirection}: 0;
        float: $rdirection;
        line-height: 1;
      }
    }
    .elc-info-icon {
      background: transparent;
      margin-bottom: -3px;
    }
  }

  // Quick View Styles
  .elc-product-quick-view-wrapper {
    .elc-product-loyalty-message {
      font-size: 17px;
      margin-top: 0;
      max-width: 350px;
      + .elc-product-prices-wrapper,
      + .elc-product-prices-clickable-wrapper {
        height: auto;
        margin: 0 0 20px;
        max-width: 350px;
        padding: 0;
        width: 100%;
        .elc-product-prices-wrapper {
          display: flex;
          flex-direction: row-reverse;
          height: auto;
          margin: 0;
          width: 100%;
          font-family: $akzidenz;
        }
        .elc-on-sale-today-label-wrapper {
          display: block;
          width: 50%;
          font-size: 17px;
        }
        .elc-product-original-price {
          font-size: 17px;
        }
        ~ .elc-product-full-cta-wrapper {
          max-width: 350px;
          align-self: self-start;
        }
        .elc-product-prices-wrapper,
        .elc-on-sale-today-label-wrapper,
        .elc-product-original-price {
          font-weight: bold;
          .logged-in &,
          .elc-user-state-logged-in & {
            font-weight: normal;
          }
        }
      }
      .elc-product-loyalty-message-modal-open:hover,
      .elc-product-loyalty-message-modal-open {
        background: transparent;
        height: 0;
        min-width: auto;
        padding: 0;
        width: auto;
        margin: 0;
      }
      .elc-product-loyalty-message-price,
      .elc-product-loyalty-message-range {
        font-family: $akzidenz;
        font-size: 17px;
        .logged-in &,
        .elc-user-state-logged-in & {
          font-weight: bold;
        }
        .loyalty-price,
        .loyalty-label-full {
          width: 50%;
        }
      }
    }
  }

  // MPP Styles
  .sd-product-grid {
    .elc-product-brief,
    .elc-product-brief-detail-wrapper {
      .elc-product-loyalty-message + .elc-product-prices-wrapper,
      .elc-product-loyalty-message + .elc-product-prices-clickable-wrapper {
        display: none;
      }
      .elc-product-loyalty-message {
        margin: 0 10px;
        @media #{$medium-up} {
          margin: 0;
        }
        .elc-product-loyalty-message-price,
        .elc-product-loyalty-message-range {
          flex-direction: row;
          font-size: 17px;
          .loyalty-label-short {
            display: block;
            .logged-in &,
            .elc-user-state-logged-in & {
              display: none;
            }
          }
          .loyalty-label-full {
            display: none;
          }
        }
      }
    }
  }

  // Global SWD discount Styles
  .elc-on-sale-today-label-wrapper + .elc-product-price-row-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0;
    .elc-discount-percent-label,
    .elc-product-original-price {
      color: $color-navy;
      font-family: $akzidenz !important;
      font-size: 22px;
      line-height: 1;
      margin-#{$rdirection}: 5px;
      padding-#{$rdirection}: 5px;
    }
    .elc-product-price {
      margin: 0;
      font-size: 22px;
    }
  }

  .elc-product-quick-view-wrapper,
  .sd-product-grid {
    .elc-product-prices-wrapper {
      .elc-on-sale-today-label-wrapper {
        display: none;
        + .elc-product-price-row-wrapper {
          .elc-product-original-price {
            font-size: 17px;
            text-decoration: none;
            padding: 0 2px 0;
            margin: 0;
          }
          .elc-product-price,
          .elc-discount-percent-label {
            display: none;
          }
        }
      }
    }
  }
}

.sd-grid-skeleton-wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 360px;
  .sd-product-grid {
    .elc-grid-item-product {
      .elc-product-short-description-wrapper {
        line-height: 18px;
        color: $color-navy;
        margin-bottom: 9px;
        font-family: $akzidenz;
        font-size: 15px;
      }
    }
  }
  .elc-product-brief-view {
    > div {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        &:not(.elc-grid-item-tout) {
          @media #{$medium-up} {
            padding-top: 0;
          }
        }
      }
    }
  }
}

.sd-grid-skeleton-items {
  display: grid;
  grid-auto-flow: dense;
  gap: 0;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  margin-top: 0;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(33.3333%, 1fr));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(33.3333%, 1fr));
  }
  &.is-slider {
    @media (min-width: 1024px) {
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    }
  }
}

.sd-grid-skeleton {
  .skeleton-item {
    padding: 8px;
    grid-column-end: span 1;
    grid-row-end: span 1;
    width: 100%;

    @media (min-width: 768px) {
      grid-column-end: span 1;
      grid-row-end: span 1;
      width: auto;
    }

    .has-alt-image {
      img.elc-img {
        height: auto;
        width: 100%;
        .is-slider & {
          height: 100%;
          width: auto;
          max-width: 100%;
        }
      }
      .elc-img--alt {
        display: none;
      }
      &:hover {
        .elc-img {
          display: none;
        }
        .elc-img--alt {
          display: block;
        }
      }
    }
  }
  .skeleton-shade {
    width: 23px;
    margin-inline: 4px;
    height: 23px;
    border-radius: 50%;
    @media (min-width: 1024px) {
      margin-inline: 0;
    }
  }
  .elc-product-shade-picker-title {
    display: none;
  }
  .skeleton-shades-grid {
    display: flex;
    justify-content: space-around;
    .slick-prev {
      #{$ldirection}: -6px;
    }
    .elc-slider-arrow-wrapper.slick-next {
      #{$rdirection}: -6px;
    }
    .elc-slider-arrow-left,
    .elc-slider-arrow-right {
      width: 16px;
      height: 16px;
      background: $color-black;
      position: relative;
      top: 8px;
    }
    .elc-slider-arrow-left {
      @include svg-icon-inline-mask('arrow-left.svg');
    }
    .elc-slider-arrow-right {
      @include svg-icon-inline-mask('arrow-right.svg');
      #{$rdirection}: -2px;
    }
  }
  .sd-grid-skeleton-items.is-slider {
    .skeleton-item {
      .has-alt-image {
        img.elc-img {
          height: 100%;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}

@if $best-shade-message {
  .sd-product-grid,
  .elc-product-quick-view-wrapper {
    @include best-shade-message();
    .elc-shade-image-selected.elc-best-shade {
      &::after {
        position: absolute;
      }
    }
  }
}
